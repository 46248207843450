import React from 'react';
import { motion } from 'framer-motion';
import { faDroplet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Offer = () => {
  return (
    <section id="offer" className="bg-gray-100">
      <motion.div
        initial={{
          y: 500,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
      >
        <div>
          <h2 className="text-3xl font-extrabold mb-10 text-center">
            Nasza oferta
          </h2>
          <div className="flex items-center justify-between mb-10">
            <div className="hidden sm:block">
              <FontAwesomeIcon icon={faDroplet} className="w-10 h-10 mr-6" />
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                Woda ze studni nie spełnia twoich oczekiwań?
              </p>
              <p className="text-lg">
                Skontaktuj się z nami. Uzyskasz wiele informacji, które pozwolą
                Ci uniknąć kosztownych błędów związanych z jej użytkowaniem.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between mb-10">
            <div className="hidden sm:block">
              <FontAwesomeIcon icon={faDroplet} className="w-10 h-10 mr-6" />
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                Jesteś specjalistą utrzymania ruchu? Twoje urządzenia szwankują
                z powodu złej jakości wody?
              </p>
              <p className="text-lg">
                Wiemy jak Ci pomóc. Wspólnie opracujemy najwłaściwszą dla
                twojego środowiska koncepcję uzdatniania i kondycjonowania wody.
                Naprawimy, zakonserwujemy i zaopiekujemy się twoimi urządzeniami
                do uzdatniania wody. Dostarczymy materiały eksploatacyjne i
                części zamienne.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between mb-10">
            <div className="hidden sm:block">
              <FontAwesomeIcon icon={faDroplet} className="w-10 h-10 mr-6" />
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                Potrzebujesz obsługi technicznej w ramach stałej umowy, lub
                indywidualnych zleceń?
              </p>
              <p className="text-lg">
                Nasi serwisanci dojadą do Ciebie, a Twoje urządzenia trafią do
                naszego kalendarza serwisowego – będziemy za Ciebie pamiętać o
                terminach przeglądów.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between mb-10">
            <div className="hidden sm:block">
              <FontAwesomeIcon icon={faDroplet} className="w-10 h-10 mr-6" />
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                Masz sprecyzowane potrzeby, posiadasz wyniki badania wody, znasz
                parametry swojej instalacji?
              </p>
              <p className="text-lg">
                Prześlij nam te informacje mailem. Skontaktujemy się z Tobą i
                zaproponujemy gotowe rozwiązania.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="hidden sm:block">
              <FontAwesomeIcon icon={faDroplet} className="w-10 h-10 mr-6" />
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                Potrzebujesz doradztwa. Nie wiesz od czego zacząć?
              </p>
              <p className="text-lg">
                Napisz do nas, lub zadzwoń. Pokierujemy twoimi działaniami, tak
                abyś nie marnował czasu i środków na zbyteczne działania.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Offer;

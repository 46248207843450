import React from 'react';
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <section id="aboutus" className="bg-gray-100">
      <motion.div
        initial={{
          y: 500,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
      >
        <div>
          <h2 className="text-3xl font-extrabold mb-10 text-center">O nas</h2>
          <p className="text-lg mb-8">
            Firma <b>ZEBA</b> powstała w 2001 roku i z ogromną przyjemnością
            wspiera swoich klientów już ponad <b>20-letnim doświadczeniem</b>.
          </p>
          <p className="text-lg mb-16">
            Zajmujemy się <b>projektowaniem, montażem, serwisem</b> oraz{' '}
            <b>
              dystrybucją filtrów i urządzeń do uzdatniania i dezynfekcji wody
            </b>
            . Specjalizujemy się w realizacjach <b>„Zaprojektuj i zbuduj”</b>,
            co pozwala inwestorowi zaoszczędzić czas i środki związane z
            poszukiwaniem projektanta, wykonawcy czy materiałów.
          </p>
          <div className="md:mx-36 font-semibold">
            <p className="text-lg">
              Realizujemy zadania związane z uzdatnianiem wody dla:
            </p>
            <ul className="list-disc text-lg pl-8 mb-16">
              <li>
                odbiorców indywidualnych w zabudowie jedno i wielorodzinnej
              </li>
              <li>wodociągów lokalnych</li>
              <li>restauracji, hoteli</li>
              <li>szpitali</li>
              <li>kotłowni wodnych i parowych</li>
              <li>instalacji chłodu</li>
              <li>technologicznych instalacji przemysłowych</li>
            </ul>
          </div>
          <p className="text-lg mb-8">
            Proponujemy kompleksowe opracowania, przygotowania{' '}
            <b>wody technologicznej</b> i <b>wysoko oczyszczonej</b> dla
            procesów <b>kosmetycznych, farmaceutycznych</b>, czy{' '}
            <b>technologii medycznych</b>.
          </p>
          <p className="text-lg">
            Zaprojektowane i wykonane przez naszych specjalistów instalacje z
            powodzeniem funkcjonują w{' '}
            <b>przemyśle spożywczym, chemicznym i zbrojeniowym</b>.
          </p>
        </div>
      </motion.div>
    </section>
  );
};

export default AboutUs;

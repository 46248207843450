import AboutUs from './components/AboutUs';
import BannerSection from './components/Banner';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Offer from './components/Offer';
import Statistics from './components/Statistics';

function App() {
  return (
    <div>
      <Navigation />
      <BannerSection />
      <AboutUs />
      <Statistics />
      <Offer />
      <Footer />
    </div>
  );
}

export default App;

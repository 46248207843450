import React, { useState } from 'react';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-100 absolute w-full">
      <div className="bg-gray-100 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-50">
        <div className="flex justify-between h-16 md:h-20">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img className="w-32 md:w-36" src="logo_zeba.png" alt="Logo" />
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <a
              href="#aboutus"
              className="pl-12 py-2 rounded-md text-md font-semibold"
            >
              O nas
            </a>
            <a
              href="#offer"
              className="pl-12 py-2 rounded-md text-md font-semibold"
            >
              Nasza oferta
            </a>
            <a
              href="#contact"
              className="pl-12 py-2 rounded-md text-md font-semibold"
            >
              Kontakt
            </a>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              type="button"
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-4003 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {isMenuOpen ? (
                  <path
                    className="inline-flex"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    className="inline-flex"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sm:hidden bg-gray-100 w-full absolute transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'top-14' : '-top-20'
        }`}
      >
        <div className="px-2 pt-2 pb-3">
          <a
            href="#aboutus"
            className="block px-3 py-2 rounded-md text-lg hover:text-gray-300"
            onClick={toggleMenu}
          >
            O nas
          </a>
          <a
            href="#offer"
            className="block px-3 py-2 rounded-md text-lg hover:text-gray-300"
            onClick={toggleMenu}
          >
            Nasza oferta
          </a>
          <a
            href="#contact"
            className="block px-3 py-2 rounded-md text-lg hover:text-gray-300"
            onClick={toggleMenu}
          >
            Kontakt
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;

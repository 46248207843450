import React, { useState } from 'react';
import Modal from 'react-modal';
import { faXmark, faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
  },
};

Modal.setAppElement('#root');

const form = `Informacje do doboru urządzeń

Data:
Nazwisko/telefon/e-mail osoby wypełniającej:
Dla kogo oferta:
Czego dotyczy:
Ujęcie wody:
-wodociąg
-studnia głębinowa
-studnia wody podskórnej
-ujęcie wody powierzchniowej
-woda deszczowa

Analiza wody:
Żelazo (mg/dm3):
Twardość ogólna (mg CaCO3/dm3):
Odczyn (pH):
Mangan (mg/dm3):
Amoniak (mg/dm3):
Azotany (mg/dm3):
Azotyny (mg/dm3):
Mętność (mg/dm3):
Barwa (mgPt/dm3):
Siarczany (mg/dm3):
Utlenialność (mg/dm3):
Inne posiadane:

Oczekiwana/wymagana jakość wody:

Do jakich celów uzdatniamy wodę:
spożywczych
grzewczych
technologicznych
innych:

Zużycie wody:
Godzinowe (m3/h):
Szczytowe (l/s):
Dobowe (m3/d):
Ilość korzystających osób:
Ilość kranów:
Ilość łazienek:
Pralka:
Zmywarka:

Wydajność pompy:

Pojemność hydroforu:`;

const Footer = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [copyText, setCopyText] = useState('Skopiuj do schowka');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCopytext = () => {
    navigator.clipboard.writeText(form);
    setCopyText('Skopiowano');
    setTimeout(() => setCopyText('Skopiuj do schowka'), 2000);
  };

  return (
    <footer
      id="contact"
      className="py-8 text-gray-100"
      style={{ backgroundColor: '#013169' }}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <FontAwesomeIcon
          icon={faXmark}
          className="w-6 h-6 absolute right-2 cursor-pointer"
          onClick={closeModal}
        />
        <span
          className="cursor-pointer text-base font-semibold italic hover"
          onClick={() => handleCopytext()}
        >
          {copyText}{' '}
          {copyText === 'Skopiowano' ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faCopy} />
          )}
        </span>
        <h2 className="text-base mr-8 mt-4">Informacje do doboru urządzeń</h2>
        <br />
        <p className="font-semibold">Data:</p>
        <p className="font-semibold">
          Nazwisko/telefon/e-mail osoby wypełniającej:
        </p>
        <p className="font-semibold">Dla kogo oferta:</p>
        <p className="font-semibold">Czego dotyczy:</p>
        <p className="font-semibold">Ujęcie wody:</p>
        <p>wodociąg</p>
        <p>studnia głębinowa</p>
        <p>studnia wody podskórnej</p>
        <p>ujęcie wody powierzchniowej</p>
        <p>woda deszczowa</p>
        <br />
        <p className="font-semibold">Analiza wody:</p>
        <p>Żelazo (mg/dm3):</p>
        <p>Twardość ogólna (mg CaCO3/dm3):</p>
        <p>Odczyn (pH):</p>
        <p>Mangan (mg/dm3):</p>
        <p>Amoniak (mg/dm3):</p>
        <p>Azotany (mg/dm3):</p>
        <p>Azotyny (mg/dm3):</p>
        <p>Mętność (mg/dm3):</p>
        <p>Barwa (mgPt/dm3):</p>
        <p>Siarczany (mg/dm3):</p>
        <p>Utlenialność (mg/dm3):</p>
        <p>Inne posiadane:</p>
        <br />
        <p className="font-semibold">Oczekiwana/wymagana jakość wody:</p>
        <br />
        <p className="font-semibold">Do jakich celów uzdatniamy wodę:</p>
        <p>spożywczych</p>
        <p>grzewczych</p>
        <p>technologicznych</p>
        <p>innych:</p>
        <br />
        <p className="font-semibold">Zużycie wody:</p>
        <p>Godzinowe (m3/h):</p>
        <p>Szczytowe (l/s):</p>
        <p>Dobowe (m3/d):</p>
        <p>Ilość korzystających osób:</p>
        <p>Ilość kranów:</p>
        <p>Ilość łazienek:</p>
        <p>Pralka:</p>
        <p>Zmywarka:</p>
        <br />
        <p className="font-semibold">Wydajność pompy:</p>
        <br />
        <p className="font-semibold">Pojemność hydroforu:</p>
      </Modal>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-4 lg:mb-0 ">
            <h3 className=" text-lg font-semibold">ZEBA</h3>
            <p className="mt-4">Ofiar Oświęcimskich 30</p>
            <p>58-160 Świebodzice</p>
            <p>Polska</p>
            <p className="mt-4">
              <a href="tel:+48509527723">+48 509 527 723</a>
            </p>
            <p>
              <a href="mailto:serwis@zeba.pl" className="underline">
                serwis@zeba.pl
              </a>
            </p>
            <p>
              <a href="mailto:biuro@zeba.pl" className="underline">
                biuro@zeba.pl
              </a>
            </p>
          </div>
          <div className="w-full md:w-3/4 md:pl-6">
            <h3 className=" text-lg font-semibold mb-3">Napisz do nas</h3>
            <p className="mb-3">
              Jeśli masz taką możliwość, kontaktując się z nami, prześlij
              następujące informacje:
            </p>
            <ul className="list-decimal pl-4 mb-3">
              <li>
                Badania wody, parametry instalacji -{' '}
                <a
                  href="./KWESTIONARIUSZ_DOBORU_URZĄDZEŃ.doc"
                  download
                  className="underline"
                >
                  pobierz formularz
                </a>{' '}
                lub{' '}
                <a href="#contact" onClick={openModal} className="italic">
                  skopiuj bezpośrednio do maila
                </a>
                .
              </li>
              <li>
                Nie masz w/w informacji? – opisz problem na{' '}
                <a href="mailto:serwis@zeba.pl" className="underline">
                  serwis@zeba.pl
                </a>
                .
              </li>
              <li>
                Jeśli wolisz kontakt telefoniczny - podaj nam swój nr telefonu i
                preferowane pory kontaktu. Odezwiemy się do Ciebie.
              </li>
            </ul>
            <p className="text-xs">
              Administratorem danych osobowych jest ZEBA Zenon Pietkiewicz, ul.
              Ofiar Oświęcimskich 30, 58-160 Świebodzice. Państwa dane będą
              przetwarzane jedynie w celu udzielenia odpowiedzi na przesłane
              zapytania.
            </p>
          </div>
        </div>
        <p className="text-center mt-6 text-sm">Copyright &copy; ZEBA 2023</p>
      </div>
    </footer>
  );
};

export default Footer;

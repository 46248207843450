import React from 'react';

const BannerSection = () => {
  return (
    <section className="animatedBg">
      <div className="max-w-7xl mx-auto pt-36 sm:pt-40 pb-96 sm:pb-80 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2
            className="text-3xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10 tracking-widest mb-2"
            style={{ color: '#0a272b' }}
          >
            Systemy uzdatniania wody
          </h2>
          <h3
            className="hidden sm:block text-3xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10"
            style={{ color: '#174759' }}
          >
            dla przemysłu i domu
          </h3>
          <p className="mt-4 text-lg leading-6 font-bold"></p>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;

import React from 'react';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWrench,
  faHandHoldingDroplet,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

const Statistics = () => {
  return (
    <div
      className="flex flex-wrap justify-center"
      style={{ backgroundColor: '#013169' }}
    >
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 my-8">
        <div className="bg-white rounded-lg p-6 flex flex-col items-center relative z-2">
          <FontAwesomeIcon icon={faGraduationCap} className="w-10 h-10 mb-4" />
          <h2 className="text-4xl font-bold mb-4">
            <CountUp end={20} enableScrollSpy scrollSpyOnce />+
          </h2>
          <p className="text-lg text-center">Lat doświadczenia</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 my-8">
        <div className="bg-white rounded-lg p-6 flex flex-col items-center relative z-2">
          <FontAwesomeIcon
            icon={faHandHoldingDroplet}
            className="w-10 h-10 mb-4"
          />

          <h2 className="text-4xl font-bold mb-4">
            <CountUp end={1000} separator="" enableScrollSpy scrollSpyOnce />+
          </h2>
          <p className="text-lg text-center">Realizacji</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 my-8">
        <div className="bg-white rounded-lg p-6 flex flex-col items-center relative z-2">
          <FontAwesomeIcon icon={faWrench} className="w-10 h-10 mb-4" />
          <h2 className="text-4xl font-bold mb-4">
            <CountUp end={2000} separator="" enableScrollSpy scrollSpyOnce />+
          </h2>
          <p className="text-lg text-center">Serwisowanych instalacji</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
